import React from 'react'



class Lucrative extends React.Component {

  render() {

    return (
      <div className='pt-100'>
      <section className="secureof lucrative gray-bg">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Lucrative Revenue</span> Modules of Our Crypto Exchange Development Service</h2>
          <p className="text-center">Yield a hefty amount of revenue by starting a Crypto Exchange business with our premium cryptocurrency exchange software development service.</p>
          <div className="factor">
            <div className="revenue">
              <p >Trading fees</p>
              <p >Crypto withdrawal fees</p>
            </div>
            <div className="revenue">
              <p>Fiat deposit fees</p>
              <p>Crypto Listing fees</p>
            </div>
            <div className="revenue">
              <p >Conduct token sales via IEO</p>
              <p >Advertisements</p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default Lucrative