import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'





class IdealCrypto extends React.Component {



  render() {


    return (
      <div className='pt-70'>
      <section className="whatis secureof ideal pb-3">

        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="main-heading">Coinsclone - Ideal <span className='bluecolor'>Cryptocurrency Exchange</span> Development Company</h2>
              </div>
            </div>
              <p className="pharagraph text-center">Coinsclone developers have rock-solid experience in developing crypto products for successful outcomes. The defense-in-depth mechanisms we enable for your cryptocurrency exchange website and cryptocurrency exchange mobile application will safeguard your cryptocurrency exchange from all threats and vulnerabilities. Setting up a crypto exchange with the help of our talented blockchain developers would help you distinguish yourself from others and stay one step ahead of your rivals.
              </p>
              <div className='reasons text-center'>
                <div >
                    <h3>150+</h3>
                    Happy Clients
                </div>
                <div >
                    <h3>7+</h3>
                    Years of Experience
                </div>
                <div >
                    <h3>200+</h3>
                    Successful Projects
                </div>
                <div >
                    <h3>
                    <FontAwesomeIcon icon={faClock} />
                    </h3>
                    On-time Delivery
                </div>
              </div>
          </div>
        </div>
      </section>
      </div>

    )
  }
}

export default IdealCrypto