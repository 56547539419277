import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions?</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is cryptocurrency exchange software development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Cryptocurrency exchange software development is the process by which startups build a high-quality, bug-free, and multi-tested cryptocurrency exchange for starting a cryptocurrency exchange business. It is regarded as one of the best businesses for budding startups since there are multiple revenue-generating modules available on the platform.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What are the types of cryptocurrency exchanges we develop?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">We at Coinclone develop six different types of cryptocurrency exchanges which include Order Book, Derivatives, Margin Trading, OTC, P2P, and Decentralized exchange.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                What are the solutions we offer for crypto exchange software development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">By approaching us, you have three different solutions for crypto exchange development. Either you can develop from scratch, purchase our white-labeled software, or hire our expert developers to start your own crypto exchange.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                Is investing in cryptocurrency exchange development profitable?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Definitely! Cryptocurrency exchange development has a wider scope in terms of profit-making. Purchasing white-label software to start a Bitcoin exchange is affordable and has several business benefits. Therefore, entrepreneurs can invest low and make huge money in a short time.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How long would it take for cryptocurrency exchange software development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The time taken to develop a new cryptocurrency exchange is a minimum of “3 - 8 months”. It has to be noted that the time depends on various factors and complexity of the project. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                What is the cryptocurrency exchange development process?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">The following steps are the main process of developing a cryptocurrency exchange
                <ul>
                  <li><p>Requirements gathering</p></li>
                  <li><p>Planning </p></li>
                  <li><p>Designing </p></li>
                  <li><p>Developing </p></li>
                  <li><p>Testing </p></li>
                  <li><p>Deployment </p></li>
                </ul>
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle"  eventKey="6">
                How much would it cost to set up a safe cryptocurrency exchange?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">On an average, the cost to build the safest cryptocurrency exchange ranges between $10,000 to $30,000. This cost can vary depending on certain factors while constructing the exchange. For higher business requirements, the Crypto exchange development cost can go upto $1,00,000.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection