import React from "react"

class DevMethod extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod gray-bg">
        <div className="container">
          <h2 className="heading-h2 text-center">
          Our Cryptocurrency Exchange Software <span className="bluecolor">Development Methods</span>
          </h2>
          <div className="d-lg-flex">
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/development-from-scratch.png"
                    alt="Cryptocurrency Exchange Development From Scratch"
                  />
                  Development From Scratch
                </div>
                <p className="pharagraph">
                A traditional crypto exchange development method where our developers focus on building a Cryptocurrency Exchange from scratch. The crypto exchange development process can take up to a year and adequate technical coding knowledge of our developers. The process of creating a crypto asset exchange is quite expensive due to the complexity involved.
                </p>
              </div>
              <div className="square">
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/hire-our-developers.png"
                    alt="Hire our developers"
                  />
                  Clone Script
                </div>
                <p className="pharagraph">
                Clone script is the simplest and most affordable way to build a Cryptocurrency Exchange. It is a pre-coded cryptocurrency exchange software that replicates the entire functionalities of a popular cryptocurrency exchange platform. Our clone script is customizable and doesn’t need much technical coding work.
                </p>
              </div>
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/clone-script.png"
                    alt="Clone Script"
                  />
                  Hire Our Developers
                </div>
                <p className="pharagraph">
                To establish a top-class feature-rich cryptocurrency exchange, our developers are ideal. Our expert group will make Your Own Cryptocurrency Exchange on time without bugs or any errors.
                </p>
              </div>
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default DevMethod
