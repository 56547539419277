import React from 'react'



class Addonmode extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="icon secureof pt-100 padremove">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Add-On Modules</span> of Our Cryptocurrency Exchange Software Development</h2>
          <p className="text-center">Enable the add-on modules to start your own crypto exchange business and achieve the business goals as quickly as possible.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/staking.png" alt="Staking" />
                  <span>Staking </span>
                  </div>
                <p className="pharagraph">Starting a Crypto Exchange with this functionality allows your users to lock cryptocurrencies for a specific period and receive rewards via locked cryptos. Furthermore, the platform administrator(you) can increase the cryptocurrency exchange liquidity.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/margin-trading.png" alt='Margin trading' />
                 <span>Margin trading</span> 
                </div>
                <p className="pharagraph">By borrowing funds from the cryptocurrency exchange administrator(you), your users can raise the quantity of their capital. It allows your users to make money by carrying out profitable deals.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/futures-trading.png" alt='Futures Trading' />
                <span>Futures Trading</span>
                </div>
                <p className="pharagraph">It permits your users to exchange cryptocurrencies at a fixed price with no expiry date. Futures trading is enabled on your cryptocurrency exchange and the app allows your users to maintain their position by speculating on the future price of a specific cryptocurrency.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/referral-program.png" alt='Referral program' />
                <span>Referral program</span>
                </div>
                <p className="pharagraph">Make Your Own Cryptocurrency Exchange with a referral program feature that allows users to make a passive income by referring new members to the cryptocurrency exchange.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'> 
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/otc-trading.png" alt='OTC trading' />
                <span>OTC trading</span>
                </div>
                <p className="pharagraph">Create a Bitcoin exchange with an OTC trading option, you could assist your users in trading cryptocurrencies in higher amounts. Incorporating this functionality into your cryptocurrency exchange will let you effortlessly impress whales and wealthy investors in the blockchain space.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/admin-profit-management.png" alt='Admin Profit Management' />
                <span>Admin Profit Management</span>
                </div>
                <p className="pharagraph">Creating your own crypto exchange with the easy-to-use admin profit management system allows you to track and handle the calculated earnings that come via commission fees, withdrawal fees, and other income sources.</p>
              </div>
            </div>
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2 d-table m-auto">Get A Free Consultation</a>
        </div>
      </section>
    )
  }
}

export default Addonmode