import React from "react"

const VariousBlockchain = () => {
  return (
    <section className="secureof variousblock pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
          <h2 className="main-heading"><span className="bluecolor">Blockchains We Prefer</span> for Cryptocurrency Exchange Software Development</h2>
          </div>
        </div>
        <div className="binanceflexbox">
          <div className="card" >
            <div className="binancecont">
              <img
                width="75"
                height="63"
                src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/ethereum.png"
                alt="Ethereum"
              />
              <p className="pharagraph" >Ethereum</p>
            </div>
          </div>
          <div className="card">
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/hyper-ledger.png"
                  alt="Hyper ledger"
                />
                <p className="pharagraph">Hyper ledger</p>
            </div>
          </div>
          <div className="card" >
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/hydrachain.png"
                  alt="hydrachain"
                />
                <p className="pharagraph" >Hydrachain</p>
            </div>
          </div>
        </div>
        <div className="binanceflexbox">
          <div className="card" >
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/chaincore.png"
                  alt="Chaincore"
                />
                <p className="pharagraph" >Chaincore</p>
            </div>
          </div>
          <div className="card">
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/stellar.png"
                  alt="Steller"
                />
                <p className="pharagraph">Stellar</p>
            </div>
          </div>
          <div className="card" >
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/tron.png"
                  alt="Tron"
                />
                <p className="pharagraph" >Tron</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VariousBlockchain
