import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


const DevApproach = () => {


  return (
    <section className="devapproach mb-0 pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h3 className="heading-h2">Cryptocurrency Exchange Software <span className='bluecolor'>Development Process</span></h3>
            <p className="pharagraph head">As a prominent cryptocurrency exchange software development company, we apply the strategy outlined below to start an exchange.</p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/opensea-new/repuirement-gathering-planning.png"
              alt="Requirements Gathering"
              width={501}
              height={302}
            />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h4>Requirements Gathering</h4>
            <p className="pharagraph">Our business team analyzes the client’s requirements first to start your own Bitcoin exchange. All suitable needs of the client are then gathered by us according to the cryptocurrency exchange to be developed. The end result is finally determined by how well the requirements have been gathered and hence, we pay more attention to this.
            </p>
            <br />
            <h4>Planning </h4>
            <p className="pharagraph">We plan and provide a clear understanding to the client of how we create a cryptocurrency exchange. Proper planning always helps to identify the areas that need focus and we have been one step ahead through this.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h4>Designing</h4>
            <p className="pharagraph">Our experienced developers in the blockchain department are capable of producing fabulous UI/UX designs. The cryptocurrency exchange we design is based on the latest trends in blockchain and with an eye on the user attraction aspect.</p>
            <br />
            <h4>Developing </h4>
            <p  className="pharagraph">Once the cryptocurrency exchange design is ready, we get into the development action straightaway. We ensure that the developers stick to the requirements and needs of the client throughout the cryptocurrency exchange software development process.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
          <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/opensea-new/design-and-development.png"
              alt="Designing"
              width={357}
              height={350}
            />
            </div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
          <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/opensea-new/testing-and-deployment.png"
              alt="Testing and Deployment"
              width={332}
              height={314}
            />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h4>Testing</h4>
            <p  className="pharagraph">After developing we conduct tests to identify any faults. At times, bugs or small issues might arise during testing and it will be cleared before moving onto the deployment stage.
            </p>
            <br />
            <h4>Deployment</h4>
            <p  className="pharagraph mb-0">Once the deployment stage is reached, clients can be assured of a successful cryptocurrency exchange development. From here, we deploy the cryptocurrency exchange on the server and help the clients to complete the process.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach