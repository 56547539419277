import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class CaseStudy extends React.Component {


  render() {

    return (
      <div className='landing pt-100'>
      <section className="banner  casestudy pt-0">  
        <div className='casestudy-bg'>
        <div className="container">
            <h2 className='heading-h2 text-center'>Our Recent <span className='bluecolor'>successful Work</span></h2>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/coinlocally.webp"
                    alt="Coinlocally logo"
                    width={316}
                    className='mb-4'
                />
                <ul>
                    <li><b>Coinlocally</b>, one of the best Crypto Trading Apps in the current market developed their exchange website using our software solution.</li>
                    <li>With packed security and the best liquidity, Coinlocally stormed with <b>50k downloads in just two years of time.</b></li>
                    <li>The stunning order book-based functionality in the Exchange helped Coinlocally reach about one million plus users in 3 months.</li>
                    <li>The Crypto Exchange currently has all the Trading options like derivatives, futures, etc, allowing a <b>revenue milestone of 215.5%.</b></li>
                </ul>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/coinslocally-casestudy.webp"
                alt="Coinlocally casestudy"
                width={780} 
              />
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo </a>
          </div>
        </div>
        </div>
      </section>
      </div>
    )
  }
}

export default CaseStudy