import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class BookFree extends React.Component {

  render() {

    return (
      <div className='pt-100'>
      <section className="bookfree gray-bg">
        <div className="container">
          <div className="row">
              <h3 className="heading-h2 text-center"><span className='bluecolor'>What Makes Coinsclone</span> the Best Cryptocurrency Exchange Software Development Company?</h3>
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2">
            <p className="pharagraph">Coinsclone is a leading cryptocurrency exchange software development company that provides the best crypto exchange development services with high-end features in the global market. Also, we have successfully delivered more than 150+ crypto projects with 100% client satisfaction.
              </p>
              <p className="pharagraph">Leverage our cryptocurrency exchange software development service to accomplish success with certainty.
              </p>
              <p className="pharagraph">Get in touch with our devoted team of professionals for pricing information and solve your unsolved queries with us. We offer the premier solution required to create a cryptocurrency exchange quickly as per industry standards.
              </p>
              <p className="pharagraph"><strong>Talk to our experts and experience the quality of our crypto exchange development service.</strong>
              </p>
              <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2">Get A Free Consultation</a>
            </div>
            <div className="col-lg-4 col-md-12 text-center order1" >
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/book-a-free-demo.png"
              alt="Book a Free Consultation"
              width={325}
              height={314}
            />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default BookFree