import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
                <h1 className="main-heading"><span className='bluecolor'>Cryptocurrency Exchange</span> Development Company</h1>
                <p className="pharagraph">Hire our experienced cryptocurrency exchange developers to develop a cryptocurrency exchange with compelling features to enter the next-gen financial sector. Leverage our feature-filled development service to build a cryptocurrency exchange platform promptly.</p>
                <p className="pharagraph">Get Coinsclone’s premium turnkey solution to launch your feature-rich crypto asset exchange for business.</p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/cryptocurrency-exchange-software-development.webp"
                  alt="Cryptocurrency Exchange Development Company Banner"
                  width={743}
                  className='d-none d-lg-block'
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection